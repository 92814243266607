import { Injectable } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public alertPresent: boolean;
  public loading: any;
  public isLoading: boolean = false;

  constructor(
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController
  ) {
    this.alertPresent = false;
  }

  async showError(error: string) {
    console.log('ERROR: ', error);
    if (!this.alertPresent) {
      this.toggleLoadingOff();
      this.alertPresent = true;
      let alert = await this.alertCtrl.create({
        header: 'Error!',
        message: error,
        buttons: ['OK'],
      });

      await alert.present();
      this.alertPresent = false;
    }
  }

  async messageAlert(error: string) {
    console.log('Attention! ', error);
    if (!this.alertPresent) {
      this.toggleLoadingOff();
      this.alertPresent = true;
      let alert = await this.alertCtrl.create({
        header: 'Attention!',
        subHeader: error,
        buttons: ['OK'],
      });

      await alert.present();
      this.alertPresent = false;
    }
  }

  async showLogoutError(error: string) {
    this.toggleLoadingOff();
    let alert = await this.alertCtrl.create({
      header: 'Error!',
      subHeader: error,
      buttons: ['OK'],
    });

    await alert.present();
    await alert.onDidDismiss();
  }

  async checkInAlert() {
    if (!this.alertPresent) {
      this.toggleLoadingOff();
      let alert = await this.alertCtrl.create({
        header: 'User not a part of assignment',
        subHeader: '',
        buttons: ['OK'],
      });
      await alert.present();
    }
  }

  async checkedInAlert() {
    if (!this.alertPresent) {
      this.toggleLoadingOff();
      let alert = await this.alertCtrl.create({
        header: 'Already Clocked In',
        subHeader: 'Please fix time cards for other assignments',
        buttons: ['OK'],
      });
      await alert.present();
    }
  }

  async checkOutAlert(data?: string) {
    if (!this.alertPresent) {
      this.toggleLoadingOff();
      let alert = await this.alertCtrl.create({
        header: `Not Clocked In ${data}`,
        subHeader: '',
        buttons: ['OK'],
      });
      await alert.present();
    }
  }

  async presentLoadingDefault() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.loading = await this.loadingCtrl.create({
        message: 'Please wait...',
        duration: 1000,
      });
      await this.loading.present();
    }
  }

  async toggleLoadingOff() {
    if (this.isLoading && this.loading) {
      this.isLoading = false;
      await this.loading.dismiss();
    }
  }

  async logInAlert() {
    if (!this.alertPresent) {
      this.toggleLoadingOff();
      let alert = await this.alertCtrl.create({
        header:
          'User not an employee/client yet, please contact administrator and wait for updates to appear on this screen.',
        buttons: ['OK'],
      });
      await alert.present();
    }
  }

  async locationAlert(message: string = '') {
    if (!this.alertPresent) {
      this.toggleLoadingOff();
      let alert = await this.alertCtrl.create({
        header: 'Location services not responding',
        subHeader: `Unable to clock in, please allow location services. ${message}`,
        buttons: ['OK'],
      });
      await alert.present();
    }
  }
}
