import { Timestamp } from './timestamp';
import { SquareProduct } from './squareProduct';
import { ContactInformation } from './contactinformation';

export class User {
  email: string = '';
  role: string = '';
  username: string = '';
  firstName: string = '';
  lastName: string = '';
  companyName: string = '';
  uid: string = '';
  profilePicture: string = '';
  profilePictureUid: string = '';
  profilePictureThumb: string = '';
  profilePictureThumb1: string = '';
  contactInformation: ContactInformation = new ContactInformation();
  dateCreated: Timestamp = new Timestamp(new Date().getTime() / 1000, 0);
  dateModified: Timestamp = new Timestamp(new Date().getTime() / 1000, 0);
  emailVerified: Timestamp = new Timestamp(new Date().getTime() / 1000, 0);
  active: boolean = false;
  activeSubscription: boolean = false;
  currentTimeZone: string = '';
  currentTimeZoneOffset: number = 0;
  last4: string = '';
  cardBrand: string = '';
  expMonth: string = '';
  expYear: string = '';
  defaultCardId: string = '';
  squareUserId: string = '';
  shoppingCart: SquareProduct = null;
  agreedToTerms: boolean = false;
  subscriptionPlanId: string = '';
  squareSubscriptionId: string = '';
  calicheInstanceId: string = '';

  constructor() {}

  buildUser(user: User) {
    for (var key in user) {
      if (user.hasOwnProperty(key)) {
        switch (key) {
          default:
            if (user[key] && user[key].getTime) {
              this[key] = new Timestamp(user[key].getTime() / 1000, 0);
            } else {
              this[key] = user[key] != undefined ? user[key] : null;
            }
            break;
        }
      }
    }
    return this;
  }

  toObj() {
    return {
      email: this.email,
      role: this.role,
      username: this.username,
      firstName: this.firstName,
      lastName: this.lastName,
      companyName: this.companyName,
      uid: this.uid,
      profilePicture: this.profilePicture,
      profilePictureUid: this.profilePictureUid,
      profilePictureThumb: this.profilePictureThumb,
      profilePictureThumb1: this.profilePictureThumb1,
      contactInformation:
        this.contactInformation.toObj === undefined
          ? this.contactInformation
          : this.contactInformation.toObj(),
      dateCreated:
        this.dateCreated != null &&
        typeof this.dateCreated.toDate !== 'undefined'
          ? this.dateCreated.toDate()
          : this.dateCreated,
      dateModified:
        this.dateModified != null &&
        typeof this.dateModified.toDate !== 'undefined'
          ? this.dateModified.toDate()
          : this.dateModified,
      emailVerified:
        this.emailVerified != null &&
        typeof this.emailVerified.toDate !== 'undefined'
          ? this.emailVerified.toDate()
          : this.emailVerified,
      active: this.active,
      activeSubscription: this.activeSubscription,
      last4: this.last4,
      cardBrand: this.cardBrand,
      expMonth: this.expMonth,
      expYear: this.expYear,
      defaultCardId: this.defaultCardId,
      squareUserId: this.squareUserId,
      shoppingCart:
        this.shoppingCart?.toObj === undefined
          ? this.shoppingCart
          : this.shoppingCart.toObj(),
      agreedToTerms: this.agreedToTerms,
      subscriptionPlanId: this.subscriptionPlanId,
      squareSubscriptionId: this.squareSubscriptionId,
      calicheInstanceId: this.calicheInstanceId,
    };
  }
}
