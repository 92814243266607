import { map } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Auth,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  UserCredential,
} from '@angular/fire/auth';
import {
  Firestore,
  doc,
  getDoc,
  deleteDoc,
  setDoc,
  updateDoc,
  docSnapshots,
  collection,
} from '@angular/fire/firestore';
import { AlertController } from '@ionic/angular';
import { Subject, lastValueFrom, from } from 'rxjs';
import { Timestamp } from '../models/timestamp';
import { User } from '../models/user';
import { takeUntil } from 'rxjs/operators';
import { getDownloadURL, Storage, ref } from '@angular/fire/storage';
import { SquareProduct } from '../models/squareProduct';
import { environment } from 'src/environments/environment';
import { Functions, httpsCallable } from '@angular/fire/functions';

const provider = new GoogleAuthProvider();
@Injectable({
  providedIn: 'root',
})
export class UserService {
  public defaultUserIcon: string = 'assets/defaultProfile.jpg';
  public loggedIn: boolean = false;
  isApp: boolean = false;
  isAndroid: boolean = false;
  loggedInUser: User = new User();
  appVersion: string = '0.0.006';
  compatibleVersion: boolean = false;
  serverVersion: string = '';
  userUpdateSubject: Subject<User>;
  public requestedRoute: string = '';
  public refreshRequestedRoute: string = '';
  public refreshRequestedTab: string = '';
  public selectedProduct: string = '';
  public loadingLogin: boolean = false;
  public shoppingCart: SquareProduct = null;
  private destroyed$: Subject<boolean> = new Subject();
  private allUsersForFind: Array<User> = [];
  constructor(
    public afs: Firestore,
    public alertCtrl: AlertController,
    public afAuth: Auth,
    public httpClient: HttpClient,
    public storage: Storage,
    private fns: Functions
  ) {}

  getAppVersion() {
    return docSnapshots(doc(this.afs, 'appConfig/versioning'));
  }

  async setProfilePicture(url: string, uid: string) {
    if (this.loggedInUser.profilePicture != url) {
      this.loggedInUser.profilePicture = url;
      this.loggedInUser.profilePictureUid = uid;
      this.loggedInUser.profilePictureThumb = url;
      let storageRef = ref(this.storage, `uploads/thumb@256_${uid}`);
      try {
        setTimeout(async () => {
          let url = await getDownloadURL(storageRef);
          this.loggedInUser.profilePictureThumb = url;
          console.log('UPDATING URL: ', url);
        }, 3000);
      } catch (error) {}
      await this.updateUser(this.loggedInUser);
    }
  }

  async updateProfilePictureThumb(url: string, uid: string) {
    this.loggedInUser.profilePicture = url;
    this.loggedInUser.profilePictureUid = uid;
    let storageRef = ref(this.storage, `uploads/thumb@256_${uid}`);
    try {
      let url = await getDownloadURL(storageRef);
      this.loggedInUser.profilePictureThumb = url;
      console.log('UPDATING URL: ', url);
    } catch (error) {}
    this.updateUser(this.loggedInUser);
  }

  async logUserOut() {
    this.loggedIn = false;
    this.loggedInUser = new User();

    await this.afAuth.signOut();
    window.location.reload();
  }

  getUserProfile(userUid: string) {
    const foundUser = this.allUsersForFind.find((user) => user.uid === userUid);
    if (foundUser) {
      return foundUser.profilePictureThumb == ''
        ? this.defaultUserIcon
        : foundUser.profilePictureThumb;
    }
    return this.defaultUserIcon;
  }

  async logUserInProvider() {
    const authProviderResult: UserCredential = await signInWithPopup(
      this.afAuth,
      provider
    ).catch((err) => {
      console.log(err);
      return err.message;
    });

    this.loadingLogin = true;
    let currentUser = await this.afAuth.currentUser;

    if (this.currentUserEqaulsResult(authProviderResult, currentUser)) {
      await this.checkUserExists(authProviderResult);

      let user = await this.getUser(currentUser.uid);
      if (user.uid !== '') {
        this.loggedInUser = user;
        this.loggedIn = true;
      }
    }
    this.loadingLogin = false;
    return authProviderResult;
  }

  userSubscribe(userUid: string) {
    return docSnapshots(doc(this.afs, `users/${userUid}`)).pipe(
      map((documentData) => {
        return new User().buildUser(documentData.data() as User);
      })
    );
  }

  private async addNewUserFromResult(result: UserCredential) {
    return await this.addUser(this.createUserFromCredential(result));
  }

  createUserFromCredential(result: UserCredential) {
    let user = new User();
    user.email = result.user.email;
    user.uid = result.user.uid;
    user.role = 'client';
    let names = result.user.displayName.split(' ');
    user.firstName = names.length > 0 ? names[0] : '';
    user.lastName = names.length > 1 ? names[1] : '';
    return user;
  }

  async getUser(userUid: string): Promise<User> {
    let loggedInUserDoc = await getDoc(doc(this.afs, `users/${userUid}`));
    let user = new User().buildUser(loggedInUserDoc.data() as User);
    if (user.uid !== '') {
      return user;
    }
    return null;
  }

  async checkUserExists(userCred: UserCredential): Promise<any> {
    try {
      const callable = httpsCallable(this.fns, 'checkUserExists');

      const res$ = from(
        callable({
          email: userCred.user.email,
          uid: userCred.user.uid,
          displayName: userCred.user.displayName,
        })
      );

      return await lastValueFrom(res$);
    } catch (error) {
      console.log(error);
      return 'error';
    }
  }

  private currentUserEqaulsResult(result: any, currentUser): boolean {
    return (
      result &&
      result.user &&
      result.user.uid &&
      currentUser.uid == result.user.uid
    );
  }

  resetUser(user: User) {
    const result = sendPasswordResetEmail(this.afAuth, user.email).catch(
      (err) => console.log(err)
    );

    return result;
  }

  async addUser(user: User) {
    try {
      let obj = user.toObj();
      let res = await setDoc(doc(collection(this.afs, 'users'), user.uid), obj);
      return res;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  async deleteUser(user: User) {
    await deleteDoc(doc(this.afs, `users/${user.uid}`));
  }

  async updateUser(user: User) {
    try {
      console.log('+=+=+=+=UPDATE USER+=+=+=+=');
      user.dateModified = new Timestamp(new Date().getTime() / 1000, 0);

      await updateDoc(doc(this.afs, `users/${user.uid}`), user.toObj());

      return user;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async updateUserForSignup(user: User) {
    try {
      console.log('+=+=+=+=UPDATE USER SIGNUP+=+=+=+=');
      user.dateModified = new Timestamp(new Date().getTime() / 1000, 0);

      await updateDoc(doc(this.afs, `users/${user.uid}`), {
        agreedToTerms: user.agreedToTerms,
        subscriptionPlanId: user.subscriptionPlanId,
        firstName: user.firstName,
        lastName: user.lastName,
        companyName: user.companyName,
        contactInformation:
          user.contactInformation.toObj === undefined
            ? user.contactInformation
            : user.contactInformation.toObj(),
      });

      return user;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async updateUserProfile(user: User) {
    await updateDoc(doc(this.afs, `users/${user.uid}`), {
      profilePicture: user.profilePicture,
      profilePictureUid: user.profilePictureUid,
    });
  }

  async isUserLoggedIn(): Promise<boolean> {
    try {
      // If the user is already logged in, resolve immediately
      const currentUser = await this.afAuth.currentUser;
      if (currentUser) {
        return this.fetchAndSetUserDetails(currentUser.uid);
      }

      // If the user is not logged in, wait for token changes
      return new Promise<boolean>((resolve) => {
        this.afAuth.onIdTokenChanged(async (user) => {
          if (user) {
            const success = await this.fetchAndSetUserDetails(user.uid);
            resolve(success);
          } else {
            this.handleNotLoggedIn(resolve);
          }
        });

        // Handle scenario when user is not logged in after 15 seconds
        setTimeout(() => {
          if (!this.loggedIn) {
            resolve(false);
          }
        }, 15000);
      });
    } catch (error) {
      console.log('ERROR IsUserLoggedIn', error);
      return false;
    }
  }

  private async fetchAndSetUserDetails(uid: string): Promise<boolean> {
    this.loggedInUser.uid = uid;
    this.loggedIn = true;
    this.loggedInUser.emailVerified = new Timestamp(
      new Date().getTime() / 1000,
      0
    );
    const loggedInUserDoc = await getDoc(doc(this.afs, `users/${uid}`));

    if (loggedInUserDoc) {
      this.loggedInUser = new User().buildUser(loggedInUserDoc.data() as User);
      if (
        ['admin', 'payrollManager', 'payroll', 'manager', 'employee'].includes(
          this.loggedInUser.role
        )
      ) {
        // this.getAllActiveUsers();
      } else if (this.loggedInUser.role === 'client') {
        // this.getAllActiveUsersClient();
      }
      this.destroyed$.next(null);
      this.userSubscribe(this.loggedInUser.uid)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((user) => {
          this.loggedInUser = user;
        });
      // const $killme: Subject<boolean> = new Subject();
      // this.userUpdateSubject.pipe(takeUntil($killme)).subscribe(() => {
      //   $killme.next(false);
      //   return true;
      // });
      return true;
    } else {
      return false;
    }
  }

  private handleNotLoggedIn(resolve: (value: boolean) => void): void {
    this.loggedIn = false;
    resolve(false);
  }

  getUserName(userUid: string) {
    const foundUser = this.allUsersForFind.find((user) => user.uid === userUid);
    if (foundUser) {
      const userFullName = `${foundUser.firstName} ${foundUser.lastName}`;
      return userFullName;
    }
    return 'No User Set';
  }

  async getUserNameContact(userUid: string) {
    if (userUid === 'bot') {
      return 'Caliche';
    }
    const foundUser = this.allUsersForFind.find((user) => user.uid === userUid);
    if (foundUser) {
      const userFullName = `${foundUser.firstName} ${foundUser.lastName}`;
      return userFullName;
    }
    return 'No User Set';
  }

  async getUserProfileContact(userUid: string) {
    if (userUid === 'bot') {
      return 'assets/caliche-logo-new.png';
    }
    const foundUser = this.allUsersForFind.find((user) => user.uid === userUid);
    if (foundUser) {
      return foundUser.profilePictureThumb == ''
        ? this.defaultUserIcon
        : foundUser.profilePictureThumb;
    }
    return this.defaultUserIcon;
  }
}
