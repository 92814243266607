import { DbSync } from './dbSync';
import { Timestamp } from './timestamp';

export class SystemLogsheet implements DbSync {
  uid: string = '';
  dateModified: Timestamp = new Timestamp(new Date().getTime() / 1000, 0);
  dbOriginProjectId: string = '';
  appVersion: string = '';
  os: string = '';
  isApp: boolean = false;
  log: string = '';
  email: string = '';
  name: string = '';
  page: string = '';
  pageName: string = '';
  modal: string = '';
  modalName: string = '';
  type: string = '';
  fenceStatus: string = '';
  browser: string = '';
  model: string = '';
  updateEvent: string = '';
  uploadStatus: string = '';
  uploadName: string = '';
  locationUrl: string = '';
  botChatLog: string = '';
  jsonRes: string = '';

  constructor() {}

  buildModel(model: SystemLogsheet) {
    for (var key in model) {
      if (model.hasOwnProperty(key)) {
        switch (key) {
          default:
            if (model[key] && model[key].getTime) {
              this[key] = new Timestamp(model[key].getTime() / 1000, 0);
            } else {
              this[key] = model[key] != undefined ? model[key] : null;
            }
            break;
        }
      }
    }
    return this;
  }

  toObj() {
    return {
      uid: this.uid,
      dateModified:
        this.dateModified != null &&
        typeof this.dateModified.toDate !== 'undefined'
          ? this.dateModified.toDate()
          : this.dateModified,
      dbOriginProjectId: this.dbOriginProjectId,
      appVersion: this.appVersion,
      os: this.os,
      isApp: this.isApp,
      log: this.log,
      email: this.email,
      name: this.name,
      page: this.page,
      pageName: this.pageName,
      modal: this.modal,
      modalName: this.modalName,
      type: this.type,
      fenceStatus: this.fenceStatus,
      browser: this.browser,
      model: this.model,
      updateEvent: this.updateEvent,
      uploadStatus: this.uploadStatus,
      uploadName: this.uploadName,
      locationUrl: this.locationUrl,
      botChatLog: this.botChatLog,
      jsonRes: this.jsonRes,
    };
  }
}
