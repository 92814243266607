import { ErrorHandler, Injectable } from '@angular/core';
import { Firestore, collection, doc, setDoc } from '@angular/fire/firestore';
import { AlertController } from '@ionic/angular';

import 'firebase/analytics';

import { SystemLogsheetService } from './systemLogsheet.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService implements ErrorHandler {
  public alertPresent: boolean;
  public loading: HTMLIonLoadingElement;
  public isLoading: boolean;
  public appVersion: string;
  public isApp: boolean;
  public name: string;
  public email: string;

  constructor(
    public afs: Firestore,
    public alertCtrl: AlertController,
    public systemLogsheetService: SystemLogsheetService
  ) {
    this.alertPresent = false;
    this.appVersion = '';
    this.isApp = false;
    this.name = '';
    this.email = '';
    try {
    } catch (error) {
      console.log('Browser does not support ANALYTICS');
    }
  }

  async handleError(error) {
    let errStr = error;
    let newError = {
      name: '',
      message: '',
    };
    if (typeof errStr === 'string') {
      newError = {
        name: '',
        message: errStr,
      };
    } else {
      newError = error;
    }
    let test =
      newError.message.toLocaleLowerCase().indexOf('serviceworker') < 0;
    if (test) {
      console.log('Service worker related error');
    }
    if (
      ((!this.alertPresent &&
        newError.name.toLocaleLowerCase().indexOf('http') < 0) ||
        (!this.alertPresent &&
          newError.name.toLocaleLowerCase().indexOf('http') > -1 &&
          newError.name.toLocaleLowerCase().indexOf('firebase') > -1)) &&
      !this.alertPresent &&
      newError.message &&
      newError.message.toLocaleLowerCase().indexOf('serviceworker') < 0 &&
      error.code !== 'auth/wrong-password' &&
      error.code !== 'auth/too-many-requests' &&
      error.code !== 'storage/canceled' &&
      error.code !== 'storage/object-not-found' &&
      error.code !== 'auth/email-already-in-use' &&
      error.code !== 'auth/argument-error' &&
      error !== 'Timeout'
    ) {
      this.alertPresent = true;
      this.logError(error);
      // let alert = await this.alertCtrl.create({
      //   header: 'Error! Something crashed, sending logs.',
      //   subHeader: newError.name,
      //   message: newError.message,
      //   buttons: [
      //     {
      //       text: 'Reset App',
      //       handler: (data: any) => {
      //         window.location.reload();
      //       },
      //     },
      //   ],
      // });

      // await alert.present();
      // await alert.onDidDismiss();
      this.alertPresent = false;
    } else if (
      !this.alertPresent &&
      newError.name.toLocaleLowerCase().indexOf('http') > -1 &&
      error.code !== 'auth/wrong-password' &&
      error.code !== 'auth/too-many-requests' &&
      error !== 'Timeout'
    ) {
      this.alertPresent = true;
      this.logError(error);
      // let alert = await this.alertCtrl.create({
      //   header: 'HTTP Error, non critical.',
      //   subHeader: newError.name,
      //   message: newError.message,
      //   buttons: [
      //     {
      //       text: 'Dismiss',
      //       handler: (data: any) => {},
      //     },
      //   ],
      // });

      // await alert.present();
      // await alert.onDidDismiss();
      this.alertPresent = false;
    }
  }

  async logError(error: any) {
    if (error === undefined || error === '') {
      error = {
        message:
          'Something Crashed, error unknown. No such number, no such zone...',
      };
    }
    console.log(error);
    console.log(this.systemLogsheetService.userService.appVersion);
    let message = '';
    if (error.message) {
      message = error.message;
    }

    let logObj = {
      version: `${this.systemLogsheetService.userService.appVersion} - ${
        this.systemLogsheetService.userService.isApp ? 'App' : 'Web'
      }`,
      name: `${this.systemLogsheetService.userService.loggedInUser.firstName} ${this.systemLogsheetService.userService.loggedInUser.lastName}`,
      email: this.systemLogsheetService.userService.loggedInUser.email,
      dateModified: new Date(),
      errorLog: message,
    };
    let newDoc = doc(collection(this.afs, 'logsheets'));
    await setDoc(newDoc, logObj);
  }
}
