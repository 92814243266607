export class MenuItem {
  displayName: string;
  page: string;
  navOnHome: boolean;
  expanded: boolean = false;

  constructor(displayName: string, page: string, navOnHome: boolean) {
    this.displayName = displayName;
    this.page = page;
    this.navOnHome = navOnHome;
  }
}
