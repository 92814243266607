export const environment = {
  production: true,
  cloudFunctionHost: 'us-central1-caliche-845ba',
  firebaseConfig: {
    apiKey: 'AIzaSyB6GzQuF24gWmQdgpNXS4lQyoO-Zp_H6iI',
    authDomain: 'caliche-845ba.firebaseapp.com',
    projectId: 'caliche-845ba',
    storageBucket: 'caliche-845ba.appspot.com',
    messagingSenderId: '514516910370',
    appId: '1:514516910370:web:56ec1f76b81939723ba57b',
    measurementId: 'G-08RVZZXGLH',
  },
  recaptcha: {
    siteKey: '6LdwzhYnAAAAACJkPZtWZiCiFKfSLellG4MS-t5x',
  },
};
