import { Routes } from '@angular/router';
import { InternalAuthGuardService } from './guards/internal-auth-guard.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full',
  },
  {
    path: '',
    loadComponent: () =>
      import('./landing/landing.page').then((m) => m.LandingPage),
  },
  {
    path: 'getStarted',
    loadComponent: () =>
      import('./getStarted/getStarted.page').then((m) => m.GetStartedPage),
  },
  {
    path: 'features/projects',
    loadComponent: () =>
      import('./projects/projects.page').then((m) => m.ProjectsPage),
  },
  {
    path: 'features/estimate',
    loadComponent: () =>
      import('./estimate/estimate.page').then((m) => m.EstimatePage),
  },
  {
    path: 'features/schedule',
    loadComponent: () =>
      import('./scheduling/scheduling.page').then((m) => m.SchedulePage),
  },
  {
    path: 'features/invoice',
    loadComponent: () =>
      import('./invoice/invoice.page').then((m) => m.InvoicePage),
  },
  {
    path: 'features/clientMessaging',
    loadComponent: () =>
      import('./clientMessaging/clientMessaging.page').then(
        (m) => m.ClientMessagingPage
      ),
  },
  {
    path: 'features/internalMessaging',
    loadComponent: () =>
      import('./internalMessaging/internalMessaging.page').then(
        (m) => m.InternalMessagingPage
      ),
  },
  {
    path: 'features/accounting',
    loadComponent: () =>
      import('./accounting/accounting.page').then((m) => m.AccountingPage),
  },
  {
    path: 'features/employeeManagement',
    loadComponent: () =>
      import('./employeeManagement/employeeManagement.page').then(
        (m) => m.EmployeeManagementPage
      ),
  },
  {
    path: 'features',
    loadComponent: () =>
      import('./features/features.page').then((m) => m.FeaturesPage),
  },
  {
    path: 'appConfig',
    loadComponent: () =>
      import('./appConfig/appConfig.page').then((m) => m.AppConfigPage),
  },
  {
    path: 'help',
    loadComponent: () => import('./help/help.page').then((m) => m.HelpPage),
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./core/login/pages/login/login.page').then((m) => m.LoginPage),
  },
  /// behind login
  {
    path: 'dashboard',
    loadComponent: () =>
      import('./dashboard/dashboard.page').then((m) => m.DashboardPage),
    canActivate: [InternalAuthGuardService],
  },
  {
    path: 'payment-settings',
    loadComponent: () =>
      import('./payment-settings/payment-settings.page').then(
        (m) => m.PaymentSettingsPage
      ),
    canActivate: [InternalAuthGuardService],
  },
  {
    path: 'order-status',
    loadComponent: () =>
      import('./order-status/order-status.page').then((m) => m.OrderStatusPage),
    canActivate: [InternalAuthGuardService],
  },
];
